import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Footer = () => {
    return (
        <div className='footer'>
            <h3>Contacto</h3>
            <div className='footer-container'>
                <button
                    className='mail-button'
                    onClick={() => window.location = 'mailto:may@condesasclub.com'}>
                    <FontAwesomeIcon icon={faEnvelope} />
                </button>
                <a className='mail-button'
                    href='https://www.instagram.com/condesas_productions/'
                    target='_blank'
                    rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a className='mail-button'
                    href='https://linkedin.com/company/condesas-club'
                    target='_blank'
                    rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
            </div>
        </div>
    );
};
