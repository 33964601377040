import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './views/Home';
import { Workshops } from './views/Workshops';
import { Origen } from './views/Origen';
import './App.css';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className='container'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/workshops" element={<Workshops />} />
          <Route path="/origen" element={<Origen />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
