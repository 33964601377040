import React from 'react';

export const Origen = () => {
    return (
        <div className='origin-container'>
            <h1>Origen</h1>
            <p>
                Condesas Club nació de imaginar una comunidad en torno a la danza exótica como una
                forma de terapia. La visión trataba de celebrar el alma femenina y expandir su poder de
                seducción. Sin embargo “no solo de pan vive el hombre sino de toda palabra que sale de la
                boca de Dios” por lo que los recursos de la palabra escrita han sido necesarios para poder
                asentar las columnas de esta casa: lectura, escritura y el teatro son las otras tres patas
                sobre las que queremos seguir erigiendo una escultura viva.
            </p>
            <blockquote>
                <p>
                    Mi obsesión por dar unidad a los opuestos de los arquetipos la “mujer casada/ madre
                    amorosa” con el arquetipo de “la amante” han sido mi motor de búsqueda. Digamos que no
                    he encontrado la receta de éxito. Pero como en todo proceso científico, el error forma parte
                    del proceso. Y el precio hay que pagarlo. Condesas siento que es una continuación del
                    Chicago de Bob Fosse. Esas mujeres que asesinan en su psique a sus maridos movidas su
                    espíritu indómito para lanzarse al abismo, sabiendo que más allá del tiempo y espacio,
                    existe otra dimensión a la que pertenecen.
                </p>
            </blockquote>
            <p className='firma'>
                May Jané
                <span>
                    Fundadora
                </span>
            </p>
        </div>
    );
};