import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo_white.png';

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className='navbar'>
            <div className='logo'>
                <Link to="/">
                    <img src={logo} width={200} alt='Logo Condesas Club' />
                    <span>PRODUCTIONS</span>
                </Link>
            </div>
            <button className='menu-toggle' onClick={toggleMenu}>
                {isOpen ? '☰' : '☰'}
            </button>
            <div className={`menu ${isOpen ? 'open' : ''}`}>
                <div>
                    <button className='menu-toggle' onClick={toggleMenu}>
                        {isOpen ? 'X' : '☰'}
                    </button>
                </div>
                <div>
                    <Link className='menu-link' to="/" onClick={toggleMenu}>
                        Teatro
                    </Link>
                </div>
                <div>
                    <Link className='menu-link' to="/workshops" onClick={toggleMenu}>
                        Taller
                    </Link>
                </div>
                <div>
                    <Link className='menu-link' to="/origen" onClick={toggleMenu}>
                        Origen
                    </Link>
                </div>
            </div>
        </nav>
    );
};
