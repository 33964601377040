import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

import bobfosse from '../images/workshops/bobfosse.jpg'
import bobfosse2 from '../images/workshops/bobfosse2.jpg'
import cineclub from '../images/workshops/cineclub.jpg'
import exoticpole from '../images/workshops/exoticpole.jpg'
import exoticpole0 from '../images/workshops/exoticpole0.jpg'
import exoticpole1 from '../images/workshops/exoticpole1.jpg'
import exoticpole2 from '../images/workshops/exoticpole2.jpg'
import exoticpole3 from '../images/workshops/exoticpole3.jpg'
import filosofiapractica from '../images/workshops/filosofiapractica.jpg'
import literatura2 from '../images/workshops/literatura2.jpg'
import condesa_lucelen1 from '../images/workshops/condesa_lucelen/condesa_lucelen1.png'
import condesa_lucelen2 from '../images/workshops/condesa_lucelen/condesa_lucelen2.png'
import condesa_lucelen3 from '../images/workshops/condesa_lucelen/condesa_lucelen3.png'
import condesa_private1 from '../images/workshops/condesa_private/condesa_private1.png'
import condesa_private2 from '../images/workshops/condesa_private/condesa_private2.png'
import condesa_private3 from '../images/workshops/condesa_private/condesa_private3.png'
import condesa_rebeca1 from '../images/workshops/condesa_rebeca/condesa_rebeca1.png'
import condesa_rebeca2 from '../images/workshops/condesa_rebeca/condesa_rebeca2.png'
import condesa_rebeca3 from '../images/workshops/condesa_rebeca/condesa_rebeca3.png'
import auto_expresion from '../images/workshops/cabaret_del_inconciente/auto_expresion.jpg'
import psicomagia_performance from '../images/workshops/cabaret_del_inconciente/psicomagia_performance.jpg'
import ser_y_estar from '../images/workshops/cabaret_del_inconciente/ser_y_estar.jpg'
import modulos_abril1 from '../images/workshops/modulos_abril/modulosabril.jpg'
import modulos_abril2 from '../images/workshops/modulos_abril/modulosabril2.jpg'
import modulos_abril3 from '../images/workshops/modulos_abril/modulosabril3.jpg'
import poesia1 from '../images/workshops/poesia/la poesia salva_01.jpg'
import poesia2 from '../images/workshops/poesia/la poesia salva_02.jpg'
import cinco_puertas from '../images/workshops/5_puertas.jpg'
import cabaretdance from '../images/workshops/cabaretdance.jpg'
import posters1 from '../images/workshops/posters1.png'
import posters2 from '../images/workshops/posters2.png'
import posters3 from '../images/workshops/posters3.png'

export const Workshops = () => {
    return (
        <div className='grid'>
            <h1>Taller</h1>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '10% 0% 0 0%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={bobfosse} alt='Cartel productora teatral de un taller de bob fosse' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={bobfosse2} alt='Cartel productora teatral de un taller de bob fosse 2' />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '40% 0% 0 10%' }}>
                    <img src={cineclub} alt='Cartel productora teatral de un taller de club cine' />
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '-10% 0% 0 0%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={condesa_lucelen1} alt='Cartel productora teatral de un taller de baile burlesque 1' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={condesa_lucelen2} alt='Cartel productora teatral de un taller de baile burlesque 2' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={condesa_lucelen3} alt='Cartel productora teatral de un taller de baile burlesque 3' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '25% 0% 0 0%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={exoticpole} alt='Cartel productora teatral de un taller de pole dance 1' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={exoticpole0} alt='Cartel productora teatral de un taller de pole dance 2' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={exoticpole1} alt='Cartel productora teatral de un taller de pole dance 3' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={exoticpole2} alt='Cartel productora teatral de un taller de pole dance 4' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={exoticpole3} alt='Cartel productora teatral de un taller de pole dance 5' />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '0% 0% 0 10%' }}>
                    <img src={filosofiapractica} alt='Cartel productora teatral de un taller de relacion de pareja' />
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '-5% 0% 0 55%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={condesa_private1} alt='Cartel productora teatral de un taller de private dance 1' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={condesa_private2} alt='Cartel productora teatral de un taller de private dance 2' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={condesa_private3} alt='Cartel productora teatral de un taller de private dance 3' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '-15% 0% 0 0%' }}>
                    <img src={literatura2} alt='Cartel productora teatral de un taller literario' />
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '20% 0% 0 10%', border: '1px solid black' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={posters1} alt='Cartel productora teatral de un taller de teorias de pareja' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={posters2} alt='Cartel productora teatral de un taller de ser femenino y feminismo' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={posters3} alt='Cartel productora teatral de un taller de pole dance + lap dance' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '0% 0% 0 0%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={condesa_rebeca1} alt='Cartel productora teatral de un taller de sexy flow 1' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={condesa_rebeca2} alt='Cartel productora teatral de un taller de sexy flow 2' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={condesa_rebeca3} alt='Cartel productora teatral de un taller de sexy flow 3' />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '25% 0% 0 10%' }}>
                    <img src={cabaretdance} alt='Cartel productora teatral de un taller de cabaret dance' />
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '-15% 0% 0 0%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={auto_expresion} alt='Cartel productora teatral de un taller de autoexpresion' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={psicomagia_performance} alt='Cartel productora teatral de un taller de psicomagia' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={ser_y_estar} alt='Cartel productora teatral de un taller de alquimia emocional' />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '25% 0% 0 10%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={modulos_abril1} alt='Cartel productora teatral de un taller de danza burlesque' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={modulos_abril2} alt='Cartel productora teatral de un taller de cabaret' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={modulos_abril3} alt='Cartel productora teatral de un taller de pole teatral' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '-15% 0% 0 0%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={poesia1} alt='Cartel productora teatral de un taller de poesia' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={poesia2} alt='Cartel productora teatral de un taller de poesia 2' />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '25% 0% 0 10%' }}>
                    <img src={cinco_puertas} alt='Cartel productora teatral de un taller de erotismo femenino' />
                </div>
            </div>
        </div>
    );
};
