import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

import img1 from '../images/teatro/lamesforta/lamesforta.jpg'
import img2 from '../images/teatro/acreedores/poster_1.jpg'
import img22 from '../images/teatro/acreedores/poster_2.jpg'
import img23 from '../images/teatro/acreedores/poster_3.jpg'
import img24 from '../images/teatro/acreedores/poster_4.jpg'
import img25 from '../images/teatro/acreedores/poster_5.jpg'
import img26 from '../images/teatro/acreedores/poster_6.jpg'
import img3 from '../images/teatro/lamasfuerte/cartel.jpg'
import img4 from '../images/teatro/monologos/inadaptades.jpg'
import img5 from '../images/teatro/monologos/destino.jpg'
import img6 from '../images/teatro/monologos/milfyway.jpeg'
import img62 from '../images/teatro/monologos/milfyway2.jpeg'
import img7 from '../images/teatro/monologos/lunaticas.jpg'

export const Home = () => {
    return (
        <div className='grid'>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '8% 0% 0 0%' }}>
                    <img src={img1} alt='Cartel productora teatral la mes forta' />
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '31% 0% 0 10%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={img2} alt='Cartel productora teatral acreedores 1' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img22} alt='Cartel productora teatral acreedores 2' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img23} alt='Cartel productora teatral acreedores 3' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img24} alt='Cartel productora teatral acreedores 4' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img25} alt='Cartel productora teatral acreedores 5' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img26} alt='Cartel productora teatral acreedores 6' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '10% 0% 0 0%' }}>
                    <img src={img3} alt='Cartel productora teatral la mas fuerte' />
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '40% 0% 0 10%' }}>
                    <img src={img4} alt='Cartel productora teatral inadaptades' />
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '45%', margin: '14% 0% 0 0%' }}>
                    <img src={img5} alt='Cartel productora teatral destino' />
                </div>
                <div className='grid-item' style={{ width: '45%', margin: '38% 0% 0 10%' }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img src={img6} alt='Cartel productora teatral milfyway' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img62} alt='Cartel productora teatral milfyway' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="grid-row">
                <div className='grid-item' style={{ width: '65%', margin: '10% 0% 0 10%' }}>
                    <img src={img7} alt='Cartel productora teatral lunaticas' />
                </div>
            </div>
        </div>
    );
};
